import { Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation } from '@angular/core';

/**
 *
 * An icon-button wraps the Icon componenet in a clickable button element.
 *
 * The icon-button has and inherent `4px` of padding on each side to allow for the `focus` and `hover` states.
 * Note this means it is 8px wider and taller than an icon component.
 *
 */
@Component({
  selector: 'nv-icon-button',
  templateUrl: './nv-icon-button.component.html',
  styleUrls: ['./nv-icon-button.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NvIconButtonComponent {
  /**
   *
   *
   */
  @Input() name: string;

  /**
   *
   *
   */
  @Input() color: string = 'blue';

  /**
   *
   *
   */
  @Input() isInverted: boolean = false;

  /**
   *
   *
   */
  @Input() disabled: boolean = false;

  /**
   *
   * The HTML `type` attribute to apply to the button.
   */
  @Input() htmlType: 'submit' | 'reset' | 'button' = 'button';

  /**
   *
   * The visually hidden ARIA label for describing icon button purpose.
   */
  @Input() ariaLabelData?: { iconButtonAriaLabel?: string } = {
    iconButtonAriaLabel: null,
  };

  /**
   *
   *
   */

  @Input() hasBorder?: boolean = false;

  @Output() clickButton: EventEmitter<any> = new EventEmitter<any>();

  @HostBinding('class') get getColorCSS () {
    return `${this.color} ${this.name}`;
  }

  @HostBinding('class.is-disabled') get isDisabledCSS () {
    return this.disabled;
  }

  @HostBinding('class.is-inverted') get isInvertedCSS () {
    return this.isInverted;
  }

  handleClick (event: Event) {
    this.clickButton.emit(true); // Emit the `press` event
  }
}
