<base-modal class="base-modal" [title]="title" [subtitle]="subtitle" isProfileMode="false">
  <nv-image-form
  [currentImageId]="currentImageId"
  [currentImagePath]="currentImagePath"
  [matchedStudentId]="matchedStudentId"
  [imageHeight]="imageHeight"
  [imageWidth]="imageWidth"
  [searchEnabled]="true"
  [students]="students"
  [hasSubmitButton]="hasSubmitButton"
  [submitText]="'Submit Name'"
  [submitButtonType]="'text'"
  [images]="images"
  [hasArchiveButton]="hasArchiveButton"
  [archiveEnabled]="archiveEnabled"
  (submitEmitter)="onSubmit($event)"
  (closeModalEmitter)="onCancel()"
  [isDisabledSearch]="disabledSearch"
  ></nv-image-form>
</base-modal>
