<button
[ngClass] = "{
  'borderless-icon-button': !hasBorder, 
  'bordered-icon-button': hasBorder
}"
matRipple
[type]="htmlType"
[disabled]="disabled"
(click)="handleClick($event)"
nvAriaLabel
[ariaLabelText]="ariaLabelData.iconButtonAriaLabel"
>
  <nv-icon 
    [name]="name"
    [color]="color"
    [isInverted]="isInverted"
    aria-hidden="true"
  ></nv-icon>
</button>

