import { Injectable } from '@angular/core';

export interface IImageDetails {
  status: string;
  group: string;
  id: string;
  url: string;
  matched_student_id?: string;
}

export interface IMockRegentsExamGroup {
  examGroups: any[];
  examStatuses: any[];
}

@Injectable({
  providedIn: 'root',
})
export class GraphQLImageHelperService {
  constructor () { }
  public getImageDetailsQuery ({ contextPartnerId, group, status }): string {
    const additionalFields = (status === 'Processing' || status === 'Updated') ? 'matched_student_id' : '';

    const query = `{
      ImageDetails(contextPartnerId: "${contextPartnerId}", group: "${group}", status: "${status}") {
        status
        group
        id
        url
        ${additionalFields}
      }
    }`;

    return query;
  }

  public getHighResImageQuery ({ contextPartnerId, thumbnailId }): string {
    const query = `{
      HighResImage(contextPartnerId: "${contextPartnerId}", thumbnailId: "${thumbnailId}") {
        status
        group
        id
        url
      }
    }`;

    return query;
  }

  public getUpdateMockRegentsStudentReviewMutationQuery (
    _contextPartnerId: string,
    _sheetS3PathCropped: string,
    _updateFields: { matchedStudentId?: string; status?: string },
  ): string {
    const query = `
      mutation UpdateStudentReviewData(
        $contextPartnerId: String!,
        $sheetS3PathCropped: String!,
        $updateFields: MockRegentsReviewStudentUpdateInput!
      ) {
        updateMockRegentsReviewStudentData(
          contextPartnerId: $contextPartnerId,
          sheetS3PathCropped: $sheetS3PathCropped,
          updateFields: $updateFields
        )
      }
    `;

    return query;
  }

  public getMockRegentsExamGroupQuery ({ contextPartnerId, status }): string {
    const query = `{
      MockRegentsExamGroup(contextPartnerId: "${contextPartnerId}", status: "${status}") {
        examGroups {
          examName
          examNameHuman
          examCount
        }
        examStatuses {
          status
          statusCount
        }
      }
    }`;
    return query;
  }
}
