<div class="student-search">
  <ng-container *ngIf="(filteredStudents$ | async) as filteredStudents">
    <nv-textbox-virtual-scroll
    (clearValue)="clearSearch()"
    [selected]="activeStudent"
    (selectOption)="goToStudent($event)"
    [autocompleteOptions]="filteredStudents"
    [textControl]="studentSearchControl"
    hasClearBtn="true"
    returnObject="true"
    emptyStateText="{{ emptyStateText }}"
    placeholder="{{ placeholder }}"
    [customViewportHeight]="menuHeight"
    [customStylingWrapperId]="customStylingWrapperId"
    [autocompleteHasActiveState]="autocompleteHasActiveState"
    [isDisabled]="isDisabled"
    [icon]="hasIcon ? 'search-large-black' : null"
  ></nv-textbox-virtual-scroll>

  </ng-container>
</div>