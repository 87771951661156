import { NvTextboxVirtualScrollModule } from '../../../../nvps-libraries/design/nv-textbox-virtual-scroll/nv-textbox-virtual-scroll.module';
import { CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// TODO: Add once design for autocomplete is finalized
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { StudentSearchComponent } from './student-search.component';
import { NvIconButtonModule } from 'Src/nvps-libraries/design/nv-icon-button/nv-icon-button.module';

@NgModule({
  imports: [
    MatIconModule,
    // TODO: Add once design for autocomplete is finalized
    MatFormFieldModule,
    MatAutocompleteModule,
    MatOptionModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    ScrollingModule,
    NvIconButtonModule,
    NvTextboxVirtualScrollModule,
  ],
  declarations: [StudentSearchComponent],
  exports: [StudentSearchComponent],
  providers: [ScrollingModule, CdkVirtualScrollViewport],
})
export class StudentSearchModule {}
