import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseModalComponent } from '../base-modal.component';

export interface IImageFormModalComponentData {
  title: string;
  currentImageId: string;
  currentImagePath: string;
  students: Array<any>;
  imageHeight: number;
  imageWidth: number;
  hasSubmitButton: boolean;
  hasArchiveButton: boolean;
  archiveEnabled?: boolean;
  matchedStudentId?: string;
  disabledSearch: boolean;
}

@Component({
  selector: 'image-form-modal',
  templateUrl: './image-form-modal.component.html',
  styleUrls: ['./image-form-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ImageFormModalComponent extends BaseModalComponent implements OnInit {
  public title: string;
  public currentImageId: string;
  public currentImagePath: string;
  public matchedStudentId: string;
  public students: Array<any> = [];
  public images: Array<any> = [];
  public imageHeight: number;
  public imageWidth: number;
  public hasSubmitButton: boolean;
  public hasArchiveButton: boolean;
  public archiveEnabled: boolean;
  public disabledSearch: boolean;

  constructor (
    public dialogRef: MatDialogRef<ImageFormModalComponent>,

    @Inject(MAT_DIALOG_DATA) public data: IImageFormModalComponentData,
  ) {
    super(dialogRef);
  }

  ngOnInit (): void {
    this.title = this.data.title;
    this.currentImageId = this.data.currentImageId;
    this.currentImagePath = this.data.currentImagePath;
    this.matchedStudentId = this.data.matchedStudentId;
    this.students = this.data.students;
    this.imageHeight = this.data.imageHeight;
    this.imageWidth = this.data.imageWidth;
    this.hasSubmitButton = this.data.hasSubmitButton;
    this.hasArchiveButton = this.data.hasArchiveButton;
    this.disabledSearch = this.data.disabledSearch;
    this.archiveEnabled = false;
  }

  onSubmit (data: any): void {
    super.close(data);
  }

  onCancel (): void {
    super.close(false);
  }
}
