
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvImageFormComponent } from './nv-image-form.component';
import { StudentSearchModule } from './../../../ng2/student/navigation/student-search/student-search.module';
import { NvButtonModule } from 'projects/shared/nvps-libraries/design/nv-button/nv-button.module';
import { NvIconButtonModule } from 'projects/shared/nvps-libraries/design/nv-icon-button/nv-icon-button.module';

@NgModule({
  imports: [
    CommonModule,
    StudentSearchModule,
    NvButtonModule,
    NvIconButtonModule,
  ],
  declarations: [NvImageFormComponent],
  exports: [NvImageFormComponent],
})
export class NvImageFormModule {}
