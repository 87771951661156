import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ModalsService } from 'Src/ng2/shared/modals/modals.service';
export interface IMiniIconButonsInfo {
  icon: string;
  action: string;
  tooltipText:string;
}

@Component({
  selector: 'nv-image-form',
  templateUrl: './nv-image-form.component.html',
  styleUrls: ['./nv-image-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class NvImageFormComponent implements OnInit {
  // Current Image Inputs
  @Input() currentImageId: string;
  @Input() currentImagePath: string;
  @Input() matchedStudentId: string;
  @Input() imageHeight: number;
  @Input() imageWidth: number;
  // Submit Button Inputs
  @Input() hasSubmitButton: boolean = false;
  @Input() submitText: string = 'Submit';
  @Input() submitButtonType: string = 'text';
  // Archive Button Inputs
  @Input() hasArchiveButton: boolean = false;
  @Input() archiveEnabled: boolean = false;
  @Input() archiveText: string = 'Archive';
  @Input() archiveButtonType: string = 'text';
  // Data Inputs
  @Input() searchEnabled: boolean;
  @Input() students: Array<any>;
  // Multiple Images
  @Input() images: any;

  @Input() isDisabledSearch: boolean = false;

  @Output() submitEmitter: EventEmitter<any> = new EventEmitter();
  @Output() closeModalEmitter: EventEmitter<string> = new EventEmitter();

  public selectedStudent;
  private initialStudent: any = null; // To track the initial student selection (status: Processing)
  public studentSearchPlaceholder = 'Enter student name or ID';
  public formComplete : boolean = false;

  constructor (
    public modalsService: ModalsService,
  ) {

  }

  ngOnInit (): void {
    this.selectedStudent = null;

    // If matchedStudentId exists, find and assign the corresponding student
    if (this.matchedStudentId && this.students?.length > 0) {
      this.selectedStudent = this.students.find((student) => student.OSIS_NUMBER === this.matchedStudentId);
      this.studentSearchPlaceholder = `${this.selectedStudent.STUDENT_NAME_FIRST_LAST} (${this.selectedStudent.OSIS_NUMBER})`;
      this.initialStudent = this.selectedStudent; // Track the initial selected student
    }

  }

  onSelectStudent ($event) {
    this.selectedStudent = this.students.find((student) => student._id === $event);
    this.formComplete = true;
  }

  public onClearStudent ($event: null) {
    this.selectedStudent = $event;
  }

  public handleAction (status: 'Archived' | 'Processing'): void {
    const data = {
      student: this.selectedStudent,
      imageId: this.currentImageId,
      status,
    };

    this.submitEmitter.emit(data);
  }

  public isSubmitEnabled (): boolean {
    return !!this.selectedStudent && this.selectedStudent !== this.initialStudent;
  }
};
