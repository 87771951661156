import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { BaseModalModule } from '../base-modal.module';
import { ImageFormModalComponent } from './image-form-modal.component';
import { NvImageFormModule } from 'Src/nvps-libraries/design/nv-image-form/nv-image-form.module';

@NgModule({
  declarations: [ImageFormModalComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    BaseModalModule,
    NvImageFormModule,
  ],
  exports: [ImageFormModalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ImageFormModalModule {}
