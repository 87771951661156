<div class="form-container" id="{{currentImageId}}">
  <div class="search-row">
    @if(students && students.length > 0) {
      <p class="caption-2">Student name or ID</p>
      <student-search
        [student]="selectedStudent"
        [students]="students"
        [placeholder]="studentSearchPlaceholder"
        [hasIcon]="false"
        [fillTextbox]="true"
        (selectedStudentId)="onSelectStudent($event)"
        (clearStudentId)="onClearStudent($event)"
        [isDisabled]="isDisabledSearch"
      ></student-search>
    }
  </div>

  <div class="image-with-icons-container">
    <div class="image-container">
      <img
        [src]="currentImagePath"
        [height]="imageHeight"
        [width]="imageWidth"
      >
    </div>
  </div>
  <div class="button-row">
    @if(hasArchiveButton) {
      <nv-button
      type="secondary"
      size="large"
      [icon]="'archive-blue'"
      (clickButton)="handleAction('Archived')"
      >
        Archive
      </nv-button>
    }

    @if(hasSubmitButton) {
      <nv-button
      type="primary"
      size="large"
      [disabled]="!isSubmitEnabled()"
      (clickButton)="handleAction('Processing')"
      >
        Submit Name
      </nv-button>
    }

  </div>
</div>