import { UtilitiesService } from 'Src/ng2/shared/services/utilities/utilities.service';
import { NvTextboxVirtualScrollComponent } from './../../../../nvps-libraries/design/nv-textbox-virtual-scroll/nv-textbox-virtual-scroll.component';
import { IACOption } from 'projects/shared/nvps-libraries/design/nv-textbox/nv-textbox.interface';
import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { compact as _compact, map as _map, reduce as _reduce } from 'lodash';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { IStudent } from '../../../shared/typings/interfaces/student.interface';
import { EM_DASH } from 'Src/ng2/shared/constants/em-dash.constant';
// Note: to change sorted order of autocomplete, add column to sort on to `DEFAULT_SORT` (CM).

@Component({
  selector: 'student-search',
  templateUrl: './student-search.component.html',
  styleUrls: ['./student-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class StudentSearchComponent implements OnInit {
  @ViewChild(NvTextboxVirtualScrollComponent) virtualScroll: NvTextboxVirtualScrollComponent;
  @Input() students: any[]; // Flattened students
  @Input() student: IStudent; // Flattened students
  @Input() placeholder: string;
  @Input() hasIcon: boolean = true;
  @Input() fillTextbox: boolean = false;
  @Input() isDisabled: boolean = false;
  @Output() selectedStudentId: EventEmitter<string> = new EventEmitter();
  @Output() clearStudentId: EventEmitter<null> = new EventEmitter();

  public studentSearchControl: FormControl = new FormControl();
  public formattedStudents: IACOption[];
  public filteredStudents$: Observable<IACOption[]>;
  public menuHeight: string = '';
  public customStylingWrapperId: string = 'virtual-scroll-student-search-v4';
  public activeStudent: any;
  public emptyStateText: string;
  public autocompleteHasActiveState = true;

  constructor (
    private UtilitiesService: UtilitiesService,
  ) { }

  public ngOnInit (): void {
    this.placeholder = this.placeholder ?? 'Search by name or ID';
    this.emptyStateText = 'No student found';

    this.formattedStudents = this.students.map((student) => {
      const formattedStudent = {
        key: student._id,
        human: this.createStudentNameDisplayText(student.studentName),
        tags: [{
          key: student._id,
          human: this.formatTags(student.studentId, student.grade),
        }],
      };
      return formattedStudent;
    });
    if (this.student) {
      this.activeStudent = this.student;
    }
    this.filteredStudents$ = this.studentSearchControl.valueChanges.pipe(
      startWith(''),
      map(val => this.filter(val)),
    );
    this.menuHeight = this.calculateMenuHeight(this.students.length);
  }

  ngOnChanges (changes: SimpleChanges) {
    if (!changes.student.isFirstChange()) this.ngOnInit();
  }

  public calculateMenuHeight (students: number): string {
    switch (students) {
      case (1):
        return 'one-item-menu';
      case (2):
        return 'two-item-menu';
      case (3):
        return 'three-item-menu';
      case (4):
        return 'four-item-menu';
      default:
        return 'full-menu';
    }
  }

  private filter (val: string) {
    if (val) {
      let searchTerms = _compact(val.replace(',', ' ').split(' '));
      searchTerms = _map(searchTerms, (term: any) => term.toLowerCase());

      const filteredStudents: IACOption[] = this.formattedStudents.filter(student => {
        return searchTerms.every(term => {
          const studentName = student.human;
          return studentName.toLowerCase().includes(term) || student.key.includes(term);
        });
      });
      this.menuHeight = this.calculateMenuHeight(filteredStudents.length);
      return filteredStudents;
    }
    this.menuHeight = this.calculateMenuHeight(this.students.length);
    return this.formattedStudents;
  }

  public goToStudent (event: IACOption): void {
    const _id = event.key;
    let studentIdIsTheSame = false;
    if (this.student) {
      const { _id: currentStudentId } = this.student;
      studentIdIsTheSame = currentStudentId === _id;
    }

    if (!studentIdIsTheSame) {
      this.selectedStudentId.emit(_id);
    } else {
      this.studentSearchControl.setValue('');
    }
    this.virtualScroll.scrollToIndex(0);
    if (this.fillTextbox) {
      const studentNumber = event.tags[0].human.split(' ')[0];
      this.studentSearchControl.setValue(`${event.human} (${studentNumber})`, { emitEvent: false });
    }
  }

  private createStudentNameDisplayText (lastFirst: string): string {
    let studentDisplayText: string;
    if (lastFirst) {
      const names = lastFirst.split(', ');
      const formattedFirstName = this.UtilitiesService.checkForHyphensDiacriticsAndSpaces(names[1]);
      const formattedLastName = this.UtilitiesService.checkForHyphensDiacriticsAndSpaces(names[0]);
      studentDisplayText = `${formattedFirstName} ${formattedLastName}`;
    } else {
      studentDisplayText = EM_DASH;
    }
    return studentDisplayText;
  }

  private formatTags (id: string, grade: string): string {
    // if there is no OSIS number, then there will be no grade; but it is possible to have an OSIS number and no grade
    if (!id) return 'No OSIS found';
    if (!grade) return `${id}   Grade -`;
    const formattedGrade: string = grade.charAt(0) === '0' ? grade.substring(1) : grade;
    return `${id}   Grade ${formattedGrade}`;
  }

  public clearSearch (): void {
    this.studentSearchControl.setValue('');
    this.clearStudentId.emit(null);
  }
}
